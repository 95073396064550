<template>
  <Layout>
    <van-pull-refresh
      style="min-height: 100%;"
      v-model="loading"
      pulling-text="Pull down to refresh..."
      loosing-text="Release to refresh..."
      loading-text="Loading..."
      @refresh="onRefresh"
    >
      <div class="upi">
        <img src="../assets/waitUPI.png" alt="" />
        <h3>Waiting for payment...</h3>
        <p>Please login to your app and accept payment from Mpurse</p>
        <hr />
        <p>
          <span>Amount</span>
          <span>₹ {{ amount }}</span>
        </p>
        <p>
          <span>Payment Method</span>
          <span>UPI</span>
        </p>
      </div>
    </van-pull-refresh>
    <div class="bottom">
      <button v-if="retryCount > 200" @click="checkStatus">
        PAY COMPLETED
      </button>
    </div>
  </Layout>
</template>

<script setup>
import Layout from "@/layout/index.vue";
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted, onBeforeUnmount } from "vue";
import { getPaymentResult, upiIntentPayment } from "@/api/payment";
import { Toast } from "vant";

let route = useRoute();
    let router = useRouter();

    // 交易数据
    let originalOrderId = "";
    let amount = route.query.amount;
    let sessionId = sessionStorage.getItem("sessionId");
    let oneTimeId = sessionStorage.getItem("onetimeId");

    onMounted(async () => {
      // 发起支付
      await intentPay();

      if (
        originalOrderId === "undefined" ||
        originalOrderId == null ||
        originalOrderId === ""
      ) {
        await router.push({
          path: "/paymentResult",
          query: {
            result: "FAILURE",
            type: "UPI",
            amount: amount,
          },
        });
      } else {
        //查询结果
        await upiResult();
      }
    });

    // 发起支付
    let intentPay = async () => {
      let data = {
        onetimeId: oneTimeId,
      };

      let response = await upiIntentPayment(data, sessionId);

      if (200 === response.code) {
        originalOrderId = response.data.originalOrderId;
        window.open(response.data.intentUrl, "_blank");
      }
    };

    let retryCount = ref(0);
    let getResult;
    //UPI支付结果
    let upiResult = async () => {
      let data = {
        originalOrderId: originalOrderId,
      };
      getPaymentResult(data, sessionId)
        .then((response) => {
          if (
            response.data &&
            (response.data.status === "SUCCESS" ||
              response.data.status === "FAILURE")
          ) {
            //已支付或支付失败
            clearTimeout(getResult);
            router.push({
              path: "/paymentResult",
              query: {
                result: response.data.status,
                type: "UPI",
                amount: amount,
                paymentId: response.data.paymentId,
                upiRefId: response.data.bankOrderId,
                // postCallbackUrl: response.data.postCallbackUrl,
              },
            });
          } else {
            //未支付重试
            retryCount.value++;
            if (retryCount.value > 200) {
              clearTimeout(getResult);
            } else {
              if (getResult == null) {
                getResult = setInterval(() => {
                  upiResult();
                }, 3000);
              }
            }
          }
        })
        .catch(function(error) {});
    };

    onBeforeUnmount(() => {
      clearTimeout(getResult);
    });

    //手动检查是否成功
    const checkStatus = () => {
      let getPaymentResultData = {
        originalOrderId: originalOrderId,
      };
      getPaymentResult(getPaymentResultData, sessionId)
        .then((response) => {
          if (
            response.data &&
            (response.data.status === "SUCCESS" ||
              response.data.status === "FAILURE")
          ) {
            router.push({
              path: "/paymentResult",
              query: {
                result: response.data.status,
                type: "UPI",
                amount: amount,
                paymentId: response.data.paymentId,
              },
            });
          } else {
            Toast("PENDING");
          }
        })
        .catch(function(error) {});
    };

    //下拉刷新
    const loading = ref(false);
    const onRefresh = () => {
      setTimeout(() => {
        loading.value = false;
        upiResult();
      }, 1000);
    };
</script>


<style scoped lang="scss" type="text/scss">
.bottom {
  width: 92%;
  position: absolute;
  bottom: 5%;
  color: #999999;
  font-size: 1.2rem;
  height: 50px;
  line-height: 50px;

  button {
    float: right;
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    color: #ffffff;
    background-color: #ff9933;
    border-radius: 48px;
  }
}

.upi {
  width: 100%;
  height: 100%;
  text-align: center;

  img {
    width: 25%;
  }

  h3 {
    margin: 5% 0;
  }

  p {
    font-size: 1rem;
    color: #666666;
    overflow: hidden;

    span:first-child {
      float: left;
      margin-bottom: 10%;
    }

    span:last-child {
      float: right;
      color: #333333;
    }
  }

  hr {
    margin: 8% 0;
    border: 1px solid #f5f5f5;
  }
}
</style>
